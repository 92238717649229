import { createReducer } from "@reduxjs/toolkit";
import { getUserReport } from "@/features/admin/userreport/actions";
import { reportState } from "@/models/userreport.model";

const initialState: reportState = {
  data: {
    year: "",
    month: "",
    month_text: "",
  },
};

export const userReportReducer = createReducer(initialState, (builder) => {
  builder.addCase(getUserReport.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default userReportReducer;
