import { FabProps } from "@mui/material";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setCookie, getCookie, deleteCookie } from "cookies-next";
import Router from "next/router";

export const getMyUnfinish = createAsyncThunk(
  "myunfinish/get",
  async (keyword?: string): Promise<any> => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    const response = await axios.get(`${url}/my-unfinished-project`, {
      headers: {
        "access-token-front": `Bearer ${getCookie("access-token-front")}`,
      },
    });
    return response.data.data;
  }
);
