import { FabProps } from "@mui/material";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setCookie, getCookie, deleteCookie } from "cookies-next";
import Router from "next/router";

export const getProductInfo = createAsyncThunk(
  "product-information/get",
  async (keyword?: string): Promise<any> => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    if (keyword) {
      const response = await axios.get(
        `${url}/product-information/get?keyword=${keyword}`,
        {
          headers: {
            "access-token": `Bearer ${getCookie("access-token")}`,
          },
        }
      );
      return response.data.data;
    } else {
      const response = await axios.get(`${url}/product-information/select`, {
        headers: {
          "access-token": `Bearer ${getCookie("access-token")}`,
        },
      });
      return response.data.data;
    }
  }
);
export const getProductInfoById = createAsyncThunk(
  "product-information/getbyid",
  async (id?: number): Promise<any> => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    if (id) {
      const response = await axios.get(
        `${url}/product-information/getbyid?id=${id}`,
        {
          headers: {
            "access-token": `Bearer ${getCookie("access-token")}`,
          },
        }
      );
      return response.data.data;
    }
  }
);
export const addProductInfo = createAsyncThunk(
  "product-information/add",
  async (data: FormData) => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    const response = await axios.post(`${url}/product-information/add`, data, {
      headers: {
        "access-token": `Bearer ${getCookie("access-token")}`,
      },
    });
    return response;
  }
);

export const deleteProductInfo = createAsyncThunk(
  "product-information/delete",
  async (id?: any) => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    await axios.delete(`${url}/product-information/delete?id=${id}`, {
      headers: {
        "access-token": `Bearer ${getCookie("access-token")}`,
      },
    });

    return { status: "success" };
  }
);

export const deleteAllProductInfo = createAsyncThunk(
  "product-information/alldelete",
  async (id?: any) => {
  const url = process.env.NEXT_PUBLIC_BASE_URL_API;
  const arrData: any = [id];
  const formData = new FormData();
  formData.append('id', arrData);
  await axios.post(`${url}/product-information/checkdelete`, formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        "access-token": `Bearer ${getCookie("access-token")}`,
    }
  })

    return { status: "success" };
  }
);

export const editProductInfo = createAsyncThunk(
  "product-information/edit",
  async (data: FormData) => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    const response = await axios.post(`${url}/product-information/edit`, data, {
      headers: {
        "access-token": `Bearer ${getCookie("access-token")}`,
      },
    });
    return response;
  }
);

export const uploadProductInfo = createAsyncThunk(
  "product-information/upload",
  async (data: FormData) => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    const response = await axios.post(
      `${url}/product-information/upload`,
      data,
      {
        headers: {
          "access-token": `Bearer ${getCookie("access-token")}`,
        },
      }
    );
    return response;
  }
);
