import { createReducer } from "@reduxjs/toolkit";
import { getMyInformation } from "@/features/my_information/actions";

const initialState: any = {
  data: [],
};

export const myInformationReducer = createReducer(initialState, (builder) => {
  builder.addCase(getMyInformation.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default myInformationReducer;
