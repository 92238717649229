import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setCookie, getCookie, deleteCookie } from "cookies-next";
import Router from "next/router";
import { appDispatch, appSelector } from "@/store/hooks";

export const login = createAsyncThunk(
  "auth/front/login",
  async (params: any): Promise<any> => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;

    const response = await axios.post(`${url}/front/login`, {
      username: params.username,
      password: params.password,
      cf_response: params.status,
    });

    const token = response.data.data.token;
    if (token != "") {
      setCookie("access-token-front", token, { maxAge: 60 * 60 * 4 });
    }
    return response.data.data;
  }
);

export const getSession = createAsyncThunk("auth/front/session", async () => {
  const url = process.env.NEXT_PUBLIC_BASE_URL_API;

  const response = await axios.get(`${url}/front/profile`, {
    headers: {
      "access-token-front": `Bearer ${getCookie("access-token-front")}`,
    },
  });

  return response.data;
});

export const logout = createAsyncThunk("auth/front/logout", async () => {
  deleteCookie("access-token-front");
  location.href = "/login";
});
