import { createReducer } from "@reduxjs/toolkit";
import { getReport, getReportById } from "@/features/admin/report/actions";
import { ReportState } from "@/models/report.model";

const initialState: ReportState = {
  data: {
    report_id: "",
    report_no: "",
    serial_no: "",
    registration_date: "",
    client_name: "",
    machine_model: "",
    controller: "",
    classification: "",
    issue_detail_alarm: "",
    issue_details: "",
    telephone_support: "",
    registration_date_start: "",
    registration_date_finish: "",
    created_at: "",
    update_date: "",
    okuma_pic: "",
    user_id: "",
    fullname: "",
  },
};

export const reportReducer = createReducer(initialState, (builder) => {
  builder.addCase(getReport.fulfilled, (state, action) => {
    state.data = action.payload;
  });
  builder.addCase(getReportById.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default reportReducer;
