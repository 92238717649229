import { createReducer } from "@reduxjs/toolkit";
import { getSearch, getSearchDetails } from "@/features/search/actions";

const initialState: any = {
  data: [],
};

export const searchReducer = createReducer(initialState, (builder) => {
  builder.addCase(getSearch.fulfilled, (state, action) => {
    state.data = action.payload;
  });
  builder.addCase(getSearchDetails.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default searchReducer;
