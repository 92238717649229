import { createReducer } from "@reduxjs/toolkit";
import {
  getClassification,
  getClassificationById,
} from "@/features/admin/classification/actions";
import { ClassificationState } from "@/models/classification.model";

const initialState: ClassificationState = {
  data: {
    classification_id: "",
    serial_no: "",
    day: "",
    status: "",
  },
};

export const classificationReducer = createReducer(initialState, (builder) => {
  builder.addCase(getClassification.fulfilled, (state, action) => {
    state.data = action.payload;
  });
  builder.addCase(getClassificationById.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default classificationReducer;
