import { FabProps } from "@mui/material";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setCookie, getCookie, deleteCookie } from "cookies-next";
import Router from "next/router";

export const getSearch = createAsyncThunk(
  "search/get",
  async (keyword?: string): Promise<any> => {
    console.log(keyword + "Search");
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    const response = await axios.get(`${url}/search?keyword=${keyword}`, {
      headers: {
        "access-token-front": `Bearer ${getCookie("access-token-front")}`,
      },
    });
    return response.data.data;
  }
);

export const getSearchDetails = createAsyncThunk(
  "search-detail/get",
  async (keyword?: string): Promise<any> => {
    console.log(keyword + "Search Details");
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    const response = await axios.get(
      `${url}/search/details?keyword=${keyword}`,
      {
        headers: {
          "access-token-front": `Bearer ${getCookie("access-token-front")}`,
        },
      }
    );
    console.log(response.data.data);
    return response.data.data;
  }
);
