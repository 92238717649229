import { createReducer } from "@reduxjs/toolkit";
import {
  getProductInfo,
  getProductInfoById,
} from "@/features/admin/product_information/actions";
import { InfoState } from "@/models/product_information.model";

const initialState: InfoState = {
  data: {
    product_information_id: "",
    serial_no: "",
    client_name: "",
    machine_model: "",
    controller: "",
    created_at: "",
  },
};

export const productInfoReducer = createReducer(initialState, (builder) => {
  builder.addCase(getProductInfo.fulfilled, (state, action) => {
    state.data = action.payload;
  });
  builder.addCase(getProductInfoById.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default productInfoReducer;
