import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setCookie, getCookie, deleteCookie } from "cookies-next";
import Router from "next/router";

export const getUserReportView = createAsyncThunk(
  "userreport/view/get",
  async ({ year, month }: any) => {
    const url = process.env.NEXT_PUBLIC_BASE_URL_API;
    const response = await axios.get(
      `${url}/user-report/view?year=${year}&month=${month}`,
      {
        headers: {
          "access-token": `Bearer ${getCookie("access-token")}`,
        },
      }
    );
    console.log(response);
    return response.data.data;
  }
);
