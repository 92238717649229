import { createReducer } from "@reduxjs/toolkit";
import { getLogsFront, getLogsBack } from "@/features/admin/login_logs/actions";
import { logsState } from "@/models/login_logs.model";

const initialState: logsState = {
  data: {
    log_id: "",
    fullname: "",
    created_at: "",
  },
};

export const logsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getLogsFront.fulfilled, (state, action) => {
    state.data = action.payload;
  });
  builder.addCase(getLogsBack.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default logsReducer;
