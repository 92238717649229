import { createReducer } from "@reduxjs/toolkit";
import { getMyUnfinish } from "@/features/my_unfinish/actions";

const initialState: any = {
  data: [],
};

export const myUnfinishReducer = createReducer(initialState, (builder) => {
  builder.addCase(getMyUnfinish.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default myUnfinishReducer;
