import {
  Action,
  combineReducers,
  AnyAction,
  ThunkAction,
  configureStore,
} from "@reduxjs/toolkit";

import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { counterReducer } from "@/features/counter";
import { kanyeReducer } from "@/features/random";
import { loginReducer } from "@/features/admin/login";
import { newsReducer } from "@/features/admin/news";
import { userReducer } from "@/features/admin/user";
import { contactReducer } from "@/features/admin/contact";
import { bannerReducer } from "@/features/admin/banner";
import { logsReducer } from "@/features/admin/login_logs";
import { reportReducer } from "@/features/admin/report";
import { productInfoReducer } from "@/features/admin/product_information";
import { userReportReducer } from "@/features/admin/userreport/reducer";
import { userReportViewReducer } from "@/features/admin/userreport/view/reducer";
import { classificationReducer } from "@/features/admin/classification";
import { loginFrontReducer } from "@/features/login";
import { myUnfinishReducer } from "@/features/my_unfinish";
import { myInformationReducer } from "@/features/my_information";
import { searchReducer } from "@/features/search";

const combinedReducer: any = combineReducers({
  counter: counterReducer,
  kanyeQuote: kanyeReducer,
  login: loginReducer,
  news: newsReducer,
  user: userReducer,
  contact: contactReducer,
  banner: bannerReducer,
  login_logs: logsReducer,
  report: reportReducer,
  productInfo: productInfoReducer,
  userReport: userReportReducer,
  userReportView: userReportViewReducer,
  classification: classificationReducer,
  login_front: loginFrontReducer,
  myUnfinish: myUnfinishReducer,
  myInformation: myInformationReducer,
  searched: searchReducer,
});

// BINDING MIDDLEWARE
const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

// const reducer = (state, action) => {
const reducer = (state: any, action: any) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    if (state.count) nextState.count = state.count; // preserve count value on client side navigation
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};
const makeStore: any = ({ isServer }: any) => {
  if (isServer) {
    //If it's on server side, create a store
    return createStore(reducer, bindMiddleware([thunkMiddleware]));
    // return createStore(rootReducer, bindMiddleware([]));
  } else {
    //If it's on client side, create a store which will persist

    const persistConfig = {
      key: "root",
      storage,
      whitelist: ["counter", "kanyeQuote"],
    };

    const persistedReducer = persistReducer(persistConfig, reducer);

    const store: any = createStore(
      persistedReducer,
      bindMiddleware([thunkMiddleware])
    );
    store.__persistor = persistStore(store);
    return store;
  }
};

type Store = ReturnType<typeof makeStore>;
export type AppState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];
export type RootState = ReturnType<Store["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const wrapper = createWrapper<Store>(makeStore);
