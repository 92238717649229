import { createReducer } from "@reduxjs/toolkit";
import { getUserReportView } from "@/features/admin/userreport/view/actions";
import { reportViewState } from "@/models/userreport.model";

const initialState: reportViewState = {
  data: {
    total_report: "",
    fullname: "",
    okuma_user_id: "",
  },
};

export const userReportViewReducer = createReducer(initialState, (builder) => {
  builder.addCase(getUserReportView.fulfilled, (state, action) => {
    state.data = action.payload;
  });
});

export default userReportViewReducer;
